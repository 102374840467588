import * as React from "react";
import {
  Accordion,
  AccordionItem
} from "@fennec/ui-common-cra/dist/components/lib/Accordion";
import styles from "./Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { useMedia } from "react-use";
import { Pressable } from "@fennec/ui-common-cra/dist/components/lib/Pressable";
import { IconChevronLeft } from "@fennec/ui-common-cra/dist/components/IconChevronLeft";
import { IconChevronRight } from "@fennec/ui-common-cra/dist/components/IconChevronRight";
import { useSDK } from "../shared/useSDK";
import { useQuery } from "react-query";
import { Avatar } from "@fennec/ui-common-cra/dist/components/lib/Avatar";
import ColorHash from "color-hash-ts";
import { getAvatarColor } from "../shared/getAvatarColor";
import { cssVar } from "../shared/cssVar";
import cx from "classcat";
import { useContent } from "@fennec/ui-glossary";

const colorHash = new ColorHash();

type SidebarContextType = {
  accordionIndexes: string[];
  setAccordionIndexes: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SidebarContext = React.createContext<SidebarContextType>({
  // @ts-ignore
  undefined
});

export function useSidebarContext() {
  return React.useContext(SidebarContext);
}

export function SidebarContextProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const [accordionIndexes, setAccordionIndexes] = React.useState<string[]>([]);
  return (
    <SidebarContext.Provider value={{ accordionIndexes, setAccordionIndexes }}>
      {children}
    </SidebarContext.Provider>
  );
}

export function Sidebar() {
  const isMinWidth = useMedia("(min-width: 1024px)");
  const [isExpanded, setIsExpanded] = React.useState(!!isMinWidth);
  const is = useContent("84433AF1-C6E8-4225-801B-DA2BC1138B9C");

  React.useEffect(() => {
    setIsExpanded(isMinWidth);
  }, [isMinWidth]);

  return (
    <div className={styles.sidebar} data-is-expanded={isExpanded}>
      <div>
        <ProjectHeader isExpanded={isExpanded} />
        <Nav isExpanded={isExpanded} />
      </div>
      <div className={styles.collapseButtonContainer}>
        <Pressable onClick={() => setIsExpanded((x) => !x)}>
          {isExpanded ? <IconChevronLeft /> : <IconChevronRight />}
          {isExpanded ? <span>{is("Collapse Sidebar")}</span> : null}
        </Pressable>
      </div>
    </div>
  );
}

type TItem = { path: string; text: string; uiKey: string };
type TNavItem = TItem | { text: string; subItems: TItem[]; uiKey: string };

function Nav(props: { isExpanded: boolean }) {
  const { isExpanded } = props;
  const is = useContent("45AF87FC-FB3B-47B3-A839-B7A785A9A5EE");
  // const location = useLocation();
  const { accordionIndexes, setAccordionIndexes } = useSidebarContext();

  const items1: TNavItem[] = [
    {
      path: "/welcome",
      text: is("Welcome"),
      uiKey: "d85e58c9-0ca1-4eb1-b0e4-a6a54b7356a3"
    },
    {
      path: "/organizations",
      text: is("Organizations"),
      uiKey: "df416b62-b142-4519-934c-2273cf9670d6"
    },
    {
      path: "/projects",
      text: is("Projects"),
      uiKey: "2fc392ed-8205-45fc-b41b-7dfd4e1068ed"
    }
    // {
    //   path: "/users",
    //   text: is("Users")
    // }
  ];

  // const items2: TNavItem[] = [];

  return (
    <nav className={styles.nav}>
      <Accordion
        type="multiple"
        value={accordionIndexes}
        onValueChange={(values) => setAccordionIndexes([...values])}>
        <ul className={styles.navList}>
          {items1.map((x, i) => (
            <NavItem
              key={i}
              index={`items1-${i}`}
              item={x}
              isExpanded={isExpanded}
            />
          ))}
        </ul>
        {/* <ul className={styles.navList}>
          {items2.map((x, i) => (
            <NavItem
              key={i}
              index={`items2-${i}`}
              item={x}
              isExpanded={isExpanded}
              accordionButtonEmphasis={(x as any).subItems?.some?.((x: any) =>
                matchPath(location.pathname, {
                  path: x.path,
                  strict: true,
                  sensitive: true
                })
              )}
            />
          ))}
        </ul> */}
      </Accordion>
    </nav>
  );
}

function NavItem(props: {
  item: any;
  index: string;
  isExpanded: boolean;
  accordionButtonEmphasis?: boolean;
}) {
  const { item, index, isExpanded, accordionButtonEmphasis } = props;

  if (item.subItems) {
    return (
      <AccordionItem
        value={index}
        buttonContent={
          <Pressable
            style={{ textAlign: "start" }}
            data-accordion-button-emphasis={accordionButtonEmphasis}>
            {isExpanded ? item.text : item.text.slice(0, 2).toLocaleUpperCase()}
          </Pressable>
        }>
        <ul className={styles.subList}>
          {item.subItems.map((x: any, ii: number) => (
            <li key={ii}>
              <NavItemLink item={x} isExpanded={isExpanded} />
            </li>
          ))}
        </ul>
      </AccordionItem>
    );
  }

  return (
    <li>
      <NavItemLink item={item} isExpanded={isExpanded} />
    </li>
  );
}

function NavItemLink(props: { item: TItem; isExpanded: boolean }) {
  const { item, isExpanded } = props;
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const params = useParams<any>();
  // const projectUuid = params.uuid || searchParams.get("p");
  // const to = generatePath(item.path, projectUuid ? { uuid: projectUuid } : {});

  return (
    <NavLink
      to={item.path}
      end={true}
      className={({ isActive }) =>
        cx([styles.link, isActive && styles.linkActive])
      }>
      {isExpanded ? item.text : item.text.slice(0, 2).toLocaleUpperCase()}
    </NavLink>
  );
}

export function WithSidebar(props: { children: React.ReactNode }) {
  const { children } = props;
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const params = useParams<any>();
  // const projectUuid = params.uuid || searchParams.get("p");
  // if (!projectUuid) {
  //   return <>{children}</>;
  // }
  return (
    <div className={styles.sidebarContainer}>
      <Sidebar />
      {children}
    </div>
  );
}

function ProjectHeader(props: { isExpanded: boolean }) {
  const { isExpanded } = props;
  const { sdk } = useSDK();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const params = useParams<any>();
  // const projectUuid = params.uuid || searchParams.get("p");
  const result = useQuery(["User"], () => sdk.getMeUser());
  const fallbackText = "User";
  if (result.status === "loading") {
    return <div className={styles.projectHeaderEmpty} />;
  }
  const name =
    result.status === "error" || result.data === undefined
      ? fallbackText
      : result.data.data.firstName + " " + result.data.data.lastName ||
        fallbackText;
  const avatarBackgroundColor =
    result.status === "error" || result.data === undefined
      ? cssVar("--pltPrimaryL") || "#bbb"
      : colorHash.hex(result.data.data.uuid);
  const avatarColor = getAvatarColor(avatarBackgroundColor);
  return (
    <div className={styles.projectHeader}>
      <Avatar
        shape="rectangular"
        fallback={name}
        fallbackBackgroundColor={avatarBackgroundColor}
        fallbackColor={avatarColor}
        size="xs"
      />
      {isExpanded ? <h2>{name}</h2> : null}
    </div>
  );
}

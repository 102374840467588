import * as React from "react";

type User = { uuid: string; selectedOrganization: { uuid: string } | null, organizationList: [{ uuid: string, name: string }] | null };

export const UserContext = React.createContext<{
  user: User;
}>({
  // @ts-ignore
  user: null,
});
